import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Credentials, CloudWatchLogs } from 'aws-sdk';
import { BehaviorSubject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ErrorDialogComponent } from 'src/@ccmc/components/error-dialog/error-dialog.component';
import { AssetService } from 'src/@ccmc/services/asset.service';
import { CustomErrorHandlerService } from 'src/@ccmc/services/custom-error-handler.service';
import { SpinnerService } from 'src/@ccmc/services/spinner.service';
import { APPCONSTANTS } from 'src/app/app.constants';
import { environment } from 'src/environments/environment';
const { Buffer } = require('buffer');

@Injectable({
  providedIn: 'root'
})
export class DocConnectorLogsService {
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };
  constructor(
    private http: HttpClient,
    private dialog: MatDialog,
    private spinnerService: SpinnerService,
    private assetService: AssetService,
    private customErrorHandlerService: CustomErrorHandlerService
  ) {
    this.httpOptions.headers.append('Access-Control-Allow-Origin', '*');
    this.httpOptions.headers.append(
      'Access-Control-Allow-Methods',
      'POST, GET, OPTIONS, PUT'
    );
    this.httpOptions.headers.append('Accept', 'application/json');
  }

  getLogs(loggingParams: any) {
    console.log(loggingParams);
    const params = {
      logGroupName: `nxtsoft-doc-connector-logs` /* required */,
      logStreamName: this.assetService.getSelectedAssetId(),
      startTime: loggingParams.startDate,
      endTime: loggingParams.endDate
    };
    return (
      this.http
        // tslint:disable-next-line: max-line-length
        .post(
          `${Buffer.from(environment.environmentURL, "base64").toString()}/cloudwatch/get-logs-by-stream`,
          params,
          this.httpOptions
        )
        .pipe(catchError(this.customErrorHandlerService.handleError))
    );
  }
}
