import {
  Component,
  OnInit,
  ViewChild,
  OnDestroy,
  ElementRef
} from '@angular/core';
import { Subject, Subscription, fromEvent } from 'rxjs';
import {
  takeUntil,
  map,
  debounceTime,
  distinctUntilChanged,
  first
} from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SpinnerService } from 'src/@ccmc/services/spinner.service';
import { AmplifyService } from 'src/@ccmc/services/amplify.service';
import { FieldEditedService } from 'src/@ccmc/services/field-edited.service';
import { ReadLoggingService } from '../read-logging.service';
import { AssetService } from 'src/@ccmc/services/asset.service';

@Component({
  selector: 'app-user-group',
  templateUrl: './user-group.component.html',
  styleUrls: ['./user-group.component.scss']
})
export class UserGroupComponent implements OnInit {
  unsubscribe: Subject<any> = new Subject();
  private logsSub: Subscription;
  private spinnerSub: Subscription;
  logs: any;
  currentLog: any;
  currentMessage: any;
  currentException: any;
  displayedColumns = ['user', 'action', 'timestamp'];
  dataSource: any;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('filterSearch', { static: true }) filterSearchEl: ElementRef;
  filterSearch: string;
  showSpinner: boolean;
  maxDate = new Date();
  endDate = new FormControl(new Date());
  startDate = new FormControl(
    new Date(this.endDate.value.getTime() - 24 * 60 * 60 * 1000)
  );
  loggingEnvironment: any;
  fieldEditedSub: any;
  message: any;

  constructor(
    private readLoggingService: ReadLoggingService,
    private spinnerService: SpinnerService,
    private amplifyService: AmplifyService,
    private fieldEditedService: FieldEditedService,
    private assetService: AssetService
  ) {}

  ngOnInit() {
    this.resetTable();
    // Get Logs
    //this.getLogs();
    // Subscribe to data needed
    this.getData();
    // Auto Focus filter search item
    this.filterSearchEl.nativeElement.focus();
    fromEvent(this.filterSearchEl.nativeElement, 'keyup')
      .pipe(
        // get value
        map((event: any) => {
          return event.target.value;
        }),
        // Time in milliseconds between key events
        debounceTime(1000),
        // If previous query is diffent from current
        distinctUntilChanged()
        // subscription for response
      )
      .subscribe((text: string) => {
        this.applyFilter(text);
      });
  }

  /**
   * Unsubscribe from observables on destroy
   *
   * @memberof LoggingComponent
   */
  ngOnDestroy() {
    this.unsubscribe.next(0);
    this.unsubscribe.complete();
  }

  /**
   * Get Data needed for logging component
   *
   * @memberof LoggingComponent
   */
  getData() {
    // Init data as empty array
    this.dataSource = new MatTableDataSource([]);
    // Subscribe to logs
    this.logsSub = this.readLoggingService.logs
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(logs => {
        if (logs) {

          
          this.logs = logs;
          console.log(this.logs);
          if (logs.length > 0) {
            for(var i = 0; i < this.logs.length; i++) {
              if(this.logs[i].action.includes('logged in')){
                this.logs.splice(i, 1);
                i--;
              }
            }
            console.log(this.logs);
            this.dataSource = new MatTableDataSource(this.logs);
            // initializes pagination
            this.dataSource.paginator = this.paginator;
            // initializes sort
            this.dataSource.sort = this.sort;
            if (this.filterSearch) {
              if (this.filterSearch.length > 0) {
                this.applyFilter(this.filterSearch);
              }
            } else {
              this.onSelect(this.dataSource.data[0]);
            }
          } else {
            this.dataSource = new MatTableDataSource([]);
            this.currentLog = undefined;
          }
        }
      });
    

    this.spinnerSub = this.spinnerService.spinner
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(spinner => {
        this.showSpinner = spinner;
      });
  }

  /**
   * Apply filter search
   *
   * @param {string} filterValue
   * @memberof LoggingComponent
   */
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.filteredData[0]) {
      this.onSelect(this.dataSource.filteredData[0]);
    }
    this.filterSearch = filterValue;
  }

  /**
   * Select current log
   *
   * @param {*} selected
   * @memberof LoggingComponent
   */
  onSelect(selected: any) {
    this.currentLog = selected;
    console.log(selected.log);
    this.jsonView(selected.log);
    this.currentException = selected.exception;
  }

  jsonView(jsonLog: any) {
    let messageValueObject = [];
    let jsonMessage;
    for (let i = 0; i < jsonLog.length; i++) {
      if (jsonLog[i].editedObject) {
        jsonMessage = {
          type: jsonLog[i].type,
          log: jsonLog[i].editedObject.oldObject
        };
        messageValueObject.push(jsonMessage);
      } else {
        messageValueObject = jsonLog;
      }
    }
    this.message = JSON.stringify(messageValueObject, null, 2);
  }

  setEnvironment(env: any) {
    this.loggingEnvironment = env;
  }
  resetTable() {
    this.fieldEditedSub = this.fieldEditedService.resetAssetLoggingTable
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(isBoolean => {
        console.log(isBoolean);
        if (isBoolean === true) {
          console.log('Reset Asset Table', this.dataSource);
          this.dataSource = new MatTableDataSource([]);
          this.readLoggingService.logsSub.next([]);
          this.fieldEditedService.resetAssetLoggingTable.next(false);
        }
      });
  }

  async getLogs() {
    if (this.showSpinner) {
      return;
    }
    this.spinnerService.setShowSpinner(true);
    this.readLoggingService.clearData();

    // Set lower and upper bounds for times
    const finalStartDate = this.startDate.value.setHours(0, 0, 0, 0);
    const finalEndDate = this.endDate.value.setHours(23, 59, 59, 999);
    const assetID = this.assetService.getSelectedAssetId();
    const loggingParams = {
      accountID: assetID,
      startDate: new Date(finalStartDate).getTime(),
      endDate: new Date(finalEndDate).getTime()
    };
    let userLoggingResponse: any;

    (await this.readLoggingService.getUserLogs(loggingParams)).subscribe((result: any) => {
      userLoggingResponse = result;
      this.readLoggingService.cleanUserLogs(userLoggingResponse.content.logs);
     });
  }

}
