import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AdminApiService } from 'src/@ccmc/services/admin-api.service';
import { AmplifyService } from 'src/@ccmc/services/amplify.service';
import { CcmcApiService } from 'src/@ccmc/services/ccmc-api.service';
import { SnackbarService } from 'src/@ccmc/services/snackbar.service';

@Component({
  selector: 'app-ibs-credential',
  templateUrl: './ibs-credential.component.html',
  styleUrls: ['./ibs-credential.component.scss']
})
export class IbsCredentialComponent implements OnInit {
  ibsUserCredentialItem: any;
  showPassword = false;
  localIbsUserCredentialItem: any;
  editedFlag = false;
  constructor(
    private ccmcApiService: CcmcApiService,
    private snackBarService: SnackbarService,
    private dialogRef: MatDialogRef<IbsCredentialComponent>,
    private adminApiService: AdminApiService,
    private amplifyService: AmplifyService
  ) {}

  ngOnInit(): void {
    this.localIbsUserCredentialItem = {
      cognitoID: this.amplifyService.getCognitoId(),
      password: '',
      username: ''
    };
    this.getCurrentCredentials();
  }

  getCurrentCredentials() {
    this.adminApiService
      .getDynamoDBItem(
        'fis_ibs_users',
        this.amplifyService.getCognitoId(),
        'cognitoID'
      )
      .subscribe(result => {
        if (result) {
          const parsedResult = JSON.parse(JSON.stringify(result));
          this.ibsUserCredentialItem = parsedResult;
          this.localIbsUserCredentialItem = JSON.parse(JSON.stringify(result));
        } else {
          this.ibsUserCredentialItem = JSON.parse(
            JSON.stringify(this.localIbsUserCredentialItem)
          );
        }
      });
  }

  updateCredentials() {
    if (this.editedFlag) {
      this.ccmcApiService
        .createDynamoDB(
          'test',
          'fis_ibs_users',
          this.localIbsUserCredentialItem
        )
        .subscribe(result => {
          if (result) {
            this.snackBarService.openSnackBar(
              'Updated IBS Credentials',
              'Okay'
            );
            this.dialogRef.close(true);
          } else {
            this.snackBarService.openSnackBar(
              'Error Updated Credentials',
              'Error'
            );
          }
        });
    }
  }

  closeDialog() {
    this.dialogRef.close(false);
  }

  checkForm() {
    this.editedFlag = true;
    if (
      this.isEquivalent(
        this.localIbsUserCredentialItem,
        this.ibsUserCredentialItem
      )
    ) {
      this.editedFlag = false;
    }
  }

  isEquivalent(a: any, b: any) {
    // Create arrays of property names
    const aProps = Object.getOwnPropertyNames(a);
    const bProps = Object.getOwnPropertyNames(b);

    // If number of properties is different,
    // objects are not equivalent
    if (aProps.length !== bProps.length) {
      return false;
    }

    for (let i = 0; i < aProps.length; i++) {
      const propName = aProps[i];
      // If values of same property are not equal,
      // objects are not equivalent
      if (a[propName] !== b[propName]) {
        return false;
      }
    }
    return true;
  }
}
