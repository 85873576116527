<div>
  <h1>Update IBS Credentials</h1>
  <div
    style="
      
      row-gap: 15px;
      padding: 15px;
      background-color: #e0e0e0;
      border-radius: 5px;
      margin-bottom: 10px;
    "
  >
  <form>
    <div>
      <mat-form-field style="width: 100%">
        <input
          style="width: 100%"
          matInput
          placeholder="IBS Username"
          name="username"
          [(ngModel)]="localIbsUserCredentialItem.username"
          (keydown.enter)="updateCredentials()"
          (ngModelChange)="checkForm()"
        />
      </mat-form-field>
    </div>
    <div>
      <mat-form-field style="width: 100%">
        <input
          style="width: 100%"
          matInput
          placeholder="IBS Password"
          name="password"
          autocomplete="new-password"
          [(ngModel)]="localIbsUserCredentialItem.password"
          (keydown.enter)="updateCredentials()"
          [type]="!showPassword ? 'password' : 'text'"
          (ngModelChange)="checkForm()"
        />
        <mat-icon matSuffix (click)="showPassword = !showPassword">{{
          showPassword ? 'visibility_off' : 'visibility'
        }}</mat-icon>
      </mat-form-field>
    </div>
  </form>
  </div>
  <!--botton row of buttons-->
  <div id="bottom-button-container">
    <!--Cancel Button-->
    <button
      mat-stroked-button
      class="cancel-btn"
      (click)="closeDialog()"
      id="cancel-button"
    >
      Close
    </button>

    <!--Continue Button-->
    <div style="text-align: right">
      <button
        mat-raised-button
        class="continue-btn"
        (click)="updateCredentials()"
        id="continue-button"
        [disabled]="editedFlag === false"
      >
        <span class="button-text">Submit &nbsp;</span>
      </button>
    </div>
  </div>
</div>
