import { Injectable, NgZone } from '@angular/core';
import { navigation } from '../../app/navigation/navigation';
import { CcmcApiService } from './ccmc-api.service';
import { SpinnerService } from './spinner.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ErrorDialogComponent } from '../components/error-dialog/error-dialog.component';
import { SuccessDialogComponent } from '../components/success-dialog/success-dialog.component';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { catchError, filter } from 'rxjs/operators';
import { CustomErrorHandlerService } from './custom-error-handler.service';
import { AssetService } from './asset.service';
import { ActiveLosService } from './active-los.service';
import { environment } from 'src/environments/environment';
import { UpdatePremierCredentialsComponent } from '../components/update-premier-credentials/update-premier-credentials.component';
import { CCMCConfirmDialogComponent } from '../components/confirm-dialog/confirm-dialog.component';
import { CaptureDataService } from './capture-data.service';
import { AmplifyService } from './amplify.service';
import { GeneralLedgerAccountingService } from './general-ledger-accounting.service';
import { GlobalSearchService } from './global-search.service';
import { TargetResponseDialogComponent } from '../components/target-response-dialog/target-response-dialog.component';
import { ShouldSendGlDialogComponent } from '../components/should-send-gl-dialog/should-send-gl-dialog.component';
import { DebitCeditCheckDialogComponent } from '../components/debit-cedit-check-dialog/debit-cedit-check-dialog.component';

const institutionNumberFieldID = '1013';
const CREDIT_DEBIT_FIELD = 'GL_1_*_04';
const GL_AMOUNT_FIELD = 'GL_1_*_05';
@Injectable({
  providedIn: 'root'
})

export class PremierService {
  dynamicNav: any = navigation;
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };
  resultTitle = 'Premier Result';

  constructor(
    private dialog: MatDialog,
    private ccmcApiService: CcmcApiService,
    private router: Router,
    private spinnerService: SpinnerService,
    private zone: NgZone,
    private http: HttpClient,
    private customErrorHandlerService: CustomErrorHandlerService,
    private assetService: AssetService,
    private amplifyService: AmplifyService,
    private activeLosService: ActiveLosService,
    private captureDataService: CaptureDataService,
    private glaService: GeneralLedgerAccountingService,
    private globalSearchService: GlobalSearchService
  ) {
    this.httpOptions.headers.append('Access-Control-Allow-Origin', '*');
    this.httpOptions.headers.append(
      'Access-Control-Allow-Methods',
      'POST, GET, OPTIONS, PUT'
    );
    this.httpOptions.headers.append('Accept', 'application/json');
  }
  /**
   * Send
   * @description Sends the loan to the Premier communicator
   * @memberof PremierService
   */
  async send(resubmit?: boolean) {

    if (!await this.premierBalanceCheck()) {
      this.spinnerService.setShowSpinner(false);
      return;
    }

    this.spinnerService.setShowSpinner(true);
    let userName = this.amplifyService.username;
    const exportObject = {
      content: {
        mapping: this.ccmcApiService.mapping,
        configurations: this.ccmcApiService.configurations,
        transactions: this.ccmcApiService.transactions,
        cognitoID: this.amplifyService.getCognitoId(),
        resubmit: resubmit || false
      },
      user: userName,
      assetID: this.assetService.getSelectedAssetId()
    };
    console.log(exportObject);
    let sendFile = this.ccmcApiService.configurations.sendFile;
    let fileNamePrefix = this.ccmcApiService.configurations.fileNamePrefix;
    let fileNameSuffix = this.ccmcApiService.configurations.fileNameSuffix;
    this.export(exportObject).subscribe(async res => {
      // Reset Resubmit flag
      console.log(res);
      const parsedResult = JSON.parse(JSON.stringify(res));
      if (parsedResult.error) {
        const errorMessage = {
          statusMessage: "Ground Control failed to receive a response back from the communicator",
          statusFlag: false
        };
        const dialogRef2 = this.zone.run(() => {
          this.dialog.open(TargetResponseDialogComponent, {
            data: errorMessage
          });
        });
        await this.ccmcApiService.logExportResponse({
          assetID: this.assetService.getSelectedAssetId(),
          loanNumber: this.getLoanNumber(),
          statusMessage: parsedResult.error,
          statusFlag: false
        });
      }
      if (parsedResult.statusFlag === true) {
        let loanNumber;
        if (parsedResult.loanNumber) {
          loanNumber = parsedResult.loanNumber;
        } else {
          loanNumber = this.getLoanNumber();
        }
        console.log(parsedResult);
        if (sendFile == undefined || sendFile == false) {
          if (fileNamePrefix == undefined) {
            fileNamePrefix = '';
          }
          if (fileNameSuffix == undefined) {
            fileNameSuffix = '';
          }
          let exportString = parsedResult.content.message;
          console.log(parsedResult.content.message);
          if (exportString != null && exportString != undefined) {
            console.log('passed exportString')
            if (parsedResult.hasOwnProperty('content')) {
              exportString = parsedResult.content.message;
            } else {
              exportString = parsedResult.statusMessage;
            }
            let split = exportString.split(',');
            let stringToExport = '';
            for (let item of split) {
              if (item[0] === '\\') {
                console.log(item[0]);
                item = item.slice(2);
                console.log(item);
                stringToExport = stringToExport.concat('\r\n').concat(item);
              } else {
                stringToExport = stringToExport.concat(',').concat(item);
              }
            }
            stringToExport = stringToExport.slice(1);
            console.log(stringToExport);
          if (parsedResult.content.files) {
            if (this.ccmcApiService.configurations.sendFile !== true && parsedResult.content.files.length <= 1) {
              const blob = new Blob([parsedResult.content.files[0].message as BlobPart], {
                type: 'text/if3;charset=utf-8;'
              });
              const url = window.URL.createObjectURL(blob);
              const a = document.createElement('a');
              a.href = url;
              a.download = `${parsedResult.content.files[0].fileName}`;
              a.click();
            } else if (this.ccmcApiService.configurations.sendFile !== true) {
              for (let file of parsedResult.content.files) {
                const blob = new Blob([file.message as BlobPart], {
                  type: 'text/if3;charset=utf-8;'
                });
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = `${file.fileName}`;
                a.click();
            }
          }
          } else {
            if (this.ccmcApiService.configurations.sendFile !== true) {
              const blob = new Blob([stringToExport as BlobPart], {
                type: 'text/if3;charset=utf-8;'
              });
              const url = window.URL.createObjectURL(blob);
              const a = document.createElement('a');
              a.href = url;
              a.download = `${parsedResult.content.fileName}`;
              a.click();
            }
          }
          }
        }
        const resultMessage = {
          statusMessage: parsedResult.statusMessage,
          loanNumber: loanNumber,
          statusFlag: true
        };
        this.captureDataService.uploadS3File(this.ccmcApiService.mapping);
        this.activeLosService.activeLosService.loanBooked(
          parsedResult,
          JSON.parse(JSON.stringify(this.ccmcApiService.configurations))
        );
        await this.ccmcApiService.logExportResponse({
          assetID: this.assetService.getSelectedAssetId(),
          loanNumber: loanNumber,
          statusMessage: parsedResult.statusMessage,
          statusFlag: parsedResult.statusFlag
        });
        this.ccmcApiService.mapping = undefined;
        this.ccmcApiService.coreSettings = undefined;
        this.ccmcApiService.conditions = undefined;
        this.ccmcApiService.mapping = undefined;
        this.ccmcApiService.supportValues = undefined;
        this.ccmcApiService.search = undefined;
        this.ccmcApiService.transactions = undefined;
        this.ccmcApiService.configurations = undefined;
        if (this.dynamicNav[1].children[1]) {
          this.dynamicNav[1].children[1].children = [];
        }
        this.dynamicNav[1].children[0].badge.title = undefined;
        const dialogRef = this.dialog.open(TargetResponseDialogComponent, {
          data: resultMessage
        });
        dialogRef.afterClosed().subscribe(result => {
          if (this.activeLosService.activeLos === 'encompass' || this.activeLosService.activeLos === 'bytepro') {
            this.router.navigate(['loan-lookup']);
          } else {
            this.router.navigate(['file-selector']);
          }
        });
      }
      if (parsedResult.statusFlag === false) {
        if (parsedResult.content && parsedResult.content.mapping) {
          // Retrieve Validation From existing mapping object
          let existingMappingCopy = JSON.parse(
            JSON.stringify(this.ccmcApiService.mapping)
          );
          for (let mappingItem of parsedResult.content.mapping) {
            let existingMappingIndex = existingMappingCopy.findIndex(
              (obj: any) => obj.fieldID === mappingItem.fieldID
            );
            if (
              existingMappingIndex > -1 &&
              existingMappingCopy[existingMappingIndex].validation
            ) {
              mappingItem.validation =
                existingMappingCopy[existingMappingIndex].validation;
            }
          }
          this.ccmcApiService.mapping = parsedResult.content.mapping;
        }
        if (parsedResult.content && parsedResult.content.transactions) {
          this.ccmcApiService.transactions = parsedResult.content.transactions;
        }
        this.globalSearchService.initData();
        const errorMessage = {
          statusMessage: parsedResult.statusMessage,
          statusFlag: false
        };
        await this.ccmcApiService.logExportResponse({
          assetID: this.assetService.getSelectedAssetId(),
          loanNumber: this.getLoanNumber(),
          statusMessage: parsedResult.statusMessage,
          statusFlag: parsedResult.statusFlag
        });
        const dialogRef = this.dialog.open(TargetResponseDialogComponent, {
          data: errorMessage
        });
        dialogRef.afterClosed().subscribe(res => {
          if (this.ccmcApiService.configurations.sendGLTransactions) {
            if (parsedResult.errorType === 'loan') {
              // If error type is loan ask user if they want to send the GL portion again
              // Resend export
              const shouldSendGLDialogRef = this.dialog.open(
                ShouldSendGlDialogComponent,
                { disableClose: true }
              );
              shouldSendGLDialogRef.afterClosed().subscribe(dialogRes => {
                if (dialogRes) {
                  // Resend export
                  this.send(true);
                }
              });
            }
          }
        });
      }
    });
  }

  /**
   * Customer Search
   * @description performs the customers search in the Premier communicator
   * @param {*} borrower
   * @param {*} callback
   * @memberof PremierService
   */
  customerSearch(borrower: any, callback: any) {
    let userName = this.amplifyService.username;

    const request = {
      content: borrower,
      assetID: this.assetService.getSelectedAssetId(),
      user: userName
    };
    console.log(request);
    request.content.cognitoID = this.amplifyService.getCognitoId();
    request.content.configurations = this.ccmcApiService.configurations;

    // retrieve institutionID from mapping
    let institutionNumberIndex = this.ccmcApiService.mapping.findIndex(
      (mapping: any) => mapping.fieldID === institutionNumberFieldID
    );
    let institutionNumber =
      this.ccmcApiService.mapping[institutionNumberIndex].fieldValue;
    request.content.institutionNumber = institutionNumber;

    this.searchCustomer(request).subscribe(res => {
      console.log(res);

      this.spinnerService.setShowSpinner(false);
      const parsedResult = JSON.parse(JSON.stringify(res));
      // console.log(parsedResult.error);
      if (parsedResult.error) {
        const errorMessage = {
          message: JSON.stringify(parsedResult.error.message),
          title: 'Premier Result'
        };

        const dialogRef = this.dialog.open(ErrorDialogComponent, {
          data: errorMessage
        });
      }
      if (parsedResult.statusFlag === true) {
        const payload = parsedResult.content;
        if (payload.length > 0) {
          const searchResult = payload;
          console.log(searchResult);
          callback(searchResult);
        } else {
          const noResult = [
            {
              firstName: 'No Result',
              lastName: '',
              taxID: '',
              CIF: '',
              address: ''
            }
          ];
          const searchResult = noResult;
          console.log(searchResult);
          callback(searchResult);
        }
      } else if (parsedResult.statusFlag === false) {
        if (
          parsedResult.statusMessage.includes(
            'SECURITY CODES MISSING OR INVALID'
          )
        ) {
          const confirmDialogRef = this.dialog.open(
            CCMCConfirmDialogComponent,
            {
              data: 'Sign on failure. Do you want to verify your credentials?'
            }
          );
          confirmDialogRef.afterClosed().subscribe(result => {
            if (result) {
              const updatePremierCredentialsComponentDialogRef =
                this.dialog.open(UpdatePremierCredentialsComponent, {
                  panelClass: 'suffix-search__panel-dialog'
                });
            }
          });
        } else if (
          parsedResult.statusMessage.includes(
            'Error occurred during search: Error: Error: Enterprise Server Did Not Respond Within Designated Timeout'
          )
        ) {
          const confirmDialogRef = this.dialog.open(
            CCMCConfirmDialogComponent,
            {
              data: 'Premier Request Timed out. Do you want to verify your credentials?'
            }
          );
          confirmDialogRef.afterClosed().subscribe(result => {
            if (result) {
              const updatePremierCredentialsComponentDialogRef =
                this.dialog.open(UpdatePremierCredentialsComponent, {
                  panelClass: 'suffix-search__panel-dialog'
                });
            }
          });
        } else if (
          parsedResult.statusMessage.includes(
            'SECURITY CODES MISSING OR INVALID'
          )
        ) {
          const confirmDialogRef = this.dialog.open(
            CCMCConfirmDialogComponent,
            {
              data: 'The security codes are missing or invalid. Do you want to verify your credentials?'
            }
          );
          confirmDialogRef.afterClosed().subscribe(result => {
            if (result) {
              const updatePremierCredentialsComponentDialogRef =
                this.dialog.open(UpdatePremierCredentialsComponent, {
                  panelClass: 'suffix-search__panel-dialog'
                });
            }
          });
        } else if (
          parsedResult.statusMessage.includes(
            'Connection failed: A connection attempt failed because the connected party did not properly respond after a period of time'
          )
        ) {
          const confirmDialogRef = this.dialog.open(
            CCMCConfirmDialogComponent,
            {
              data: 'Connection failed. Do you want to verify your credentials?'
            }
          );
          confirmDialogRef.afterClosed().subscribe(result => {
            if (result) {
              const updatePremierCredentialsComponentDialogRef =
                this.dialog.open(UpdatePremierCredentialsComponent, {
                  panelClass: 'suffix-search__panel-dialog'
                });
            }
          });
        } else if (
          parsedResult.statusMessage.includes(
            'established connection failed because connected host has failed to respond'
          )
        ) {
          const confirmDialogRef = this.dialog.open(
            CCMCConfirmDialogComponent,
            {
              data: 'Connection failed. Do you want to verify your credentials?'
            }
          );
          confirmDialogRef.afterClosed().subscribe(result => {
            if (result) {
              const updatePremierCredentialsComponentDialogRef =
                this.dialog.open(UpdatePremierCredentialsComponent, {
                  panelClass: 'suffix-search__panel-dialog'
                });
            }
          });
        } else {
          const errorMessage = {
            message: parsedResult.statusMessage,
            title: 'Search Error'
          };

          const dialogRef = this.dialog.open(ErrorDialogComponent, {
            data: errorMessage
          });
          dialogRef.afterClosed().subscribe(() => {
            callback(parsedResult.content);
          });
        }
      }
    });
  }

  /**
   * Assign Borrowers
   * @description set the field value to the customer name
   * @param {*} party
   * @memberof PremierService
   */
  assignBorrowers(party: any) {
    console.log(party);
    for (let i = 0; i < party.length; i++) {
      console.log(party[i]);
      const portfolioIndex = this.ccmcApiService.mapping.findIndex(
        (obj: any) => obj.fieldID === party[i].portfolioFieldID
      );
      if (party[i].CIF.toUpperCase() != 'NEW') {
        this.ccmcApiService.mapping[portfolioIndex].fieldValue =
          party[i].CIF.split('-')[0];
      } else {
        this.ccmcApiService.mapping[portfolioIndex].fieldValue = 'NEW';
      }
      const nameLineFieldIds = party[i].nameLineFieldID.split(',');
      for (let x = 0; x < nameLineFieldIds.length; x++) {
        const nameLineIndex = this.ccmcApiService.mapping.findIndex(
          (obj: any) => obj.fieldID === nameLineFieldIds[x].trim()
        );

        if (party[i].CIF.toUpperCase() === 'NEW') {
          this.ccmcApiService.mapping[nameLineIndex].fieldValue = 'NEW';
        } else {
          this.ccmcApiService.mapping[nameLineIndex].fieldValue =
            party[i].CIF.split('-')[1];
        }
      }
    }
  }

  /**
   * Search Customer
   * @description calls the search customer api
   * @param {*} params
   * @returns
   * @memberof PremierService
   */
  searchCustomer(params: any) {
    //trigeringPipline Remove later
    let baseUrl = this.getBaseUrl();
    console.log(JSON.stringify(params));
    return this.http
      .post(
        `${baseUrl}/fiserv/premier/search`,
        params,
        this.httpOptions
      )
      .pipe(catchError(this.customErrorHandlerService.handleError));
  }

  /**
   * Export
   * @desciption call the export api
   * @param {*} params
   * @returns
   * @memberof PremierService
   */
  export(params: any) {
    let baseUrl = this.getBaseUrl();
    console.log(params);
    return this.http
      .post(
        `${baseUrl}/fiserv/premier/export`,
        params,
        this.httpOptions
      )
      .pipe(catchError(this.customErrorHandlerService.handleError));
  }

  /**
   * auto Customer Search
   * @description performs the auto customers search in the horizon communicator
   * @param {*} borrower
   * @param {*} callback
   * @memberof IBSService
   */
  autoCustomerSearch(borrower: any, callback: any) {
    let userName = this.amplifyService.username;
    const request = {
      content: borrower,
      assetID: this.assetService.getSelectedAssetId(),
      user: userName
    };
    // retrieve institutionID from mapping
    let institutionNumberIndex = this.ccmcApiService.mapping.findIndex(
      (mapping: any) => mapping.fieldID === institutionNumberFieldID
    );
    let institutionNumber =
      this.ccmcApiService.mapping[institutionNumberIndex].fieldValue;
    request.content.institutionNumber = institutionNumber;

    console.log('Request: \n', JSON.stringify(request));
    request.content.cognitoID = this.amplifyService.getCognitoId();
    this.searchCustomer(request).subscribe(res => {
      console.log('Customer search response: ', res);
      const parsedResult = JSON.parse(JSON.stringify(res));
      console.log(parsedResult);
      if (parsedResult.error) {
        const errorMessage = {
          message: JSON.stringify(parsedResult.error.message),
          title: 'Premier Result'
        };

        const dialogRef = this.dialog.open(ErrorDialogComponent, {
          data: errorMessage
        });

        if (errorMessage.message === 'Endpoint request timed out') {
          dialogRef.afterClosed().subscribe(data => {
            if (data) {
              const dialogRef = this.dialog.open(
                UpdatePremierCredentialsComponent,
                {
                  panelClass: 'suffix-search__panel-dialog'
                }
              );
            }
          });
        }
      }
      if (parsedResult.statusFlag === true) {
        this.spinnerService.setShowSpinner(false);
        const payload = parsedResult.content;
        if (payload.length > 0) {
          const searchResult = payload;
          console.log(searchResult);
          callback(searchResult);
        } else {
          const noResult = [
            {
              firstName: 'No Result',
              lastName: '',
              taxID: '',
              CIF: '',
              address: ''
            }
          ];
          const searchResult = noResult;
          console.log(searchResult);
          callback(searchResult);
        }
      } else if (parsedResult.statusFlag === false) {
        this.spinnerService.setShowSpinner(false);
        if (
          parsedResult.statusMessage
            .toLowerCase()
            .includes('no customer record found')
        ) {
          const noResult = [
            {
              firstName: 'No Result',
              lastName: '',
              taxID: '',
              CIF: '',
              address: ''
            }
          ];
          const searchResult = noResult;
          console.log(searchResult);
          callback(searchResult);
        } else if (
          parsedResult.statusMessage.includes(
            'SECURITY CODES MISSING OR INVALID'
          )
        ) {
          console.log('Update credentials');
          const confirmDialogRef = this.dialog.open(
            CCMCConfirmDialogComponent,
            {
              data: `${parsedResult.statusMessage}. Do you want to verify your credentials?`
            }
          );
          confirmDialogRef.afterClosed().subscribe(result => {
            if (result) {
              const updatePremierCredentialsComponentDialogRef =
                this.dialog.open(UpdatePremierCredentialsComponent, {
                  panelClass: 'suffix-search__panel-dialog'
                });
            }
          });
        } else if (
          parsedResult.statusMessage.includes(
            'Error occurred during search: Error: Error: Enterprise Server Did Not Respond Within Designated Timeout'
          )
        ) {
          const confirmDialogRef = this.dialog.open(
            CCMCConfirmDialogComponent,
            {
              data: `${parsedResult.statusMessage}. Do you want to verify your credentials?`
            }
          );
          confirmDialogRef.afterClosed().subscribe(result => {
            if (result) {
              const updatePremierCredentialsComponentDialogRef =
                this.dialog.open(UpdatePremierCredentialsComponent, {
                  panelClass: 'suffix-search__panel-dialog'
                });
            }
          });
        } else {
          const errorMessage = {
            message: parsedResult.statusMessage,
            title: 'Search Error'
          };

          const dialogRef = this.dialog.open(ErrorDialogComponent, {
            data: errorMessage
          });
          callback(parsedResult);
        }
      }
    });
  }

  getLoanNumber() {
    console.log('get Loan number');
    let loanFieldIndex = this.ccmcApiService.mapping.findIndex(
      (obj: any) => obj.fieldID === '1014'
    );
    if (loanFieldIndex > -1) {
      // Return loan number
      console.log(
        'loan number',
        this.ccmcApiService.mapping[loanFieldIndex].fieldValue
      );
      return this.ccmcApiService.mapping[loanFieldIndex].fieldValue;
    }
  }
  sendProcessGLA(params: any) {
    let baseUrl = this.getBaseUrl();
    console.log(params);
    return this.http
      .post(`${baseUrl}/fiserv/premier/process-gla`, params, this.httpOptions)
      .pipe(catchError(this.customErrorHandlerService.handleError));
  }
  processGLA(glaDocument: any) {
    this.spinnerService.setShowSpinner(true);
    let userName = this.amplifyService.username;

    // this.amplifyService.user$.pipe(filter(user => !!user)).subscribe(
    //   user => {
    //     this.zone.run(() => {
    //       userName = user.username;
    //     });
    //   },
    //   err => console.log(err)
    // );
    let sortedCoreLayout = JSON.parse(JSON.stringify(glaDocument.coreLayout));
    this.glaService.sortCoreLayout(sortedCoreLayout);
    const exportObject = {
      content: {
        set: this.removeBlankAmountsFromSet(glaDocument.set, sortedCoreLayout),
        configurations: glaDocument.configurations,
        coreLayout: sortedCoreLayout,
        cognitoID: this.amplifyService.getCognitoId()
      },
      user: userName,
      assetID: this.assetService.getSelectedAssetId(),
      loanNumber: this.captureDataService.loanNumber
    };
    console.log(JSON.stringify(exportObject));
    console.log(exportObject);
    this.sendProcessGLA(exportObject).subscribe(res => {
      console.log(res);
      const parsedResult = JSON.parse(JSON.stringify(res));

      if (parsedResult.error) {
        const errorMessage = {
          message: parsedResult.error.message,
          title: this.resultTitle
        };
        const dialogRef2 = this.zone.run(() => {
          this.dialog.open(ErrorDialogComponent, {
            data: errorMessage
          });
        });
        this.ccmcApiService.logExportResponse({
          assetID: this.assetService.getSelectedAssetId(),
          loanNumber: this.captureDataService.loanNumber,
          statusMessage: parsedResult.error.message,
          statusFlag: parsedResult.statusFlag
        });
      }
      if (parsedResult.statusFlag === true) {
        const resultMessage = {
          message: parsedResult.statusMessage,
          title: this.resultTitle
        };
        this.activeLosService.activeLosService.loanBooked(
          parsedResult,
          JSON.parse(JSON.stringify(glaDocument.configurations))
        );
        this.ccmcApiService.logExportResponse({
          assetID: this.assetService.getSelectedAssetId(),
          loanNumber: this.captureDataService.loanNumber,
          statusMessage: parsedResult.statusMessage,
          statusFlag: parsedResult.statusFlag
        });
        this.glaService.setGLAObject({});
        const dialogRef = this.dialog.open(SuccessDialogComponent, {
          data: resultMessage
        });
        dialogRef.afterClosed().subscribe(result => {
          if (this.activeLosService.activeLos === 'encompass' || this.activeLosService.activeLos === 'bytepro') {
            this.router.navigate(['loan-lookup']);
          } else {
            this.router.navigate(['file-selector']);
          }
        });
      }
      if (parsedResult.statusFlag === false) {
        if (parsedResult.content && parsedResult.content.mapping) {
          this.ccmcApiService.mapping = parsedResult.content.mapping;
        }
        if (parsedResult.content && parsedResult.content.transactions) {
          this.ccmcApiService.transactions = parsedResult.content.transactions;
        }
        const errorMessage = {
          message: parsedResult.statusMessage,
          title: this.resultTitle
        };
        this.ccmcApiService.logExportResponse({
          assetID: this.assetService.getSelectedAssetId(),
          loanNumber: this.captureDataService.loanNumber,
          statusMessage: parsedResult.statusMessage,
          statusFlag: parsedResult.statusFlag
        });
        const dialogRef = this.zone.run(() => {
          this.dialog.open(ErrorDialogComponent, {
            data: errorMessage
          });
        });
      }
    });
  }

  removeBlankAmountsFromSet(set: any, targetLayout: any) {
    // Initial temp set
    let tempSet = JSON.parse(JSON.stringify(set));
    // Find Amount Index in targetlayout
    let amountIndex = targetLayout.findIndex(
      (targetItem: any) => targetItem.columnName === 'Amount'
    );
    // Set columnID
    let amountColumnID = targetLayout[amountIndex].columnID;
    // Initial cleaned transactions
    let cleanedTransactions = [];

    for (let transaction of tempSet.transactions) {
      let hasAmount = false;
      for (let col of transaction.columns) {
        // If columnId is amount and value is longer than 0 we keep
        if (col.columnID === amountColumnID && col.fieldValue.length > 0) {
          hasAmount = true;
        }
      }
      // If has amount push to new array
      if (hasAmount) {
        cleanedTransactions.push(transaction);
      }
    }
    // Reassign transactions
    tempSet.transactions = cleanedTransactions;
    // Return new set
    return tempSet;
  }

  async premierBalanceCheck() {
    return new Promise((resolve) => {
      const zeroPad = (num: any, places: any) => String(num).padStart(places, '0')
      // Initialize counters to zero
      let creditCounter = 0, debitCounter = 0, totalCounter = 0;

        // Initialize variables to keep track of indexes and transaction information
        let glInstance = 1;
        let paddedGLInstance = zeroPad(glInstance, 2);
        let creditDebitInstance = CREDIT_DEBIT_FIELD.replace('*', `${paddedGLInstance}`);
        let creditDebitInstanceIndex = this.ccmcApiService.mapping.findIndex(
          (msg: any) => msg.fieldID === creditDebitInstance
        );
        let glAmountInstance = GL_AMOUNT_FIELD.replace('*', `${paddedGLInstance}`);
        let glAmountInstanceIndex = this.ccmcApiService.mapping.findIndex(
          (msg: any) => msg.fieldID === glAmountInstance
        );
        let currentGLTransactionID = this.ccmcApiService.mapping[creditDebitInstanceIndex].transactionID;
        let transactionIndex = this.ccmcApiService.transactions.findIndex((transaction: any) => transaction.transactionID === currentGLTransactionID)
        let currentTransaction = this.ccmcApiService.transactions[transactionIndex];

        // Loop until there are no more transactions or fields to process
        while (creditDebitInstanceIndex > -1 && glAmountInstanceIndex > -1) {
          // Get values of the credit/debit field and the amount field
          const creditOrDebitField = this.ccmcApiService.mapping[creditDebitInstanceIndex].fieldValue;
          const amountField = this.ccmcApiService.mapping[glAmountInstanceIndex].fieldValue;
          const amountValue = +amountField;
          // Add to the appropriate counter based on credit/debit value
          if ((creditOrDebitField === '500' || creditOrDebitField === '306' || creditOrDebitField === '305') && currentTransaction.display) {
            debitCounter += Math.abs(amountValue)
          } else if ((creditOrDebitField === '329' || creditOrDebitField === '380' || creditOrDebitField === '400') && currentTransaction.display) {
            creditCounter += Math.abs(amountValue)
          }
          // Increment instance and update indexes for next loop iteration
          glInstance++;
          paddedGLInstance = zeroPad(glInstance, 2);
          creditDebitInstance = CREDIT_DEBIT_FIELD.replace('*', `${paddedGLInstance}`);
          creditDebitInstanceIndex = this.ccmcApiService.mapping.findIndex(
            (msg: any) => msg.fieldID === creditDebitInstance
          );
          if (creditDebitInstanceIndex > -1) {
            glAmountInstance = GL_AMOUNT_FIELD.replace('*', `${paddedGLInstance}`);
            glAmountInstanceIndex = this.ccmcApiService.mapping.findIndex(
              (msg: any) => msg.fieldID === glAmountInstance
            );
            currentGLTransactionID = this.ccmcApiService.mapping[creditDebitInstanceIndex].transactionID;
          }
          if (transactionIndex > -1) {
            transactionIndex = this.ccmcApiService.transactions.findIndex((transaction: any) => transaction.transactionID === currentGLTransactionID)
            currentTransaction = this.ccmcApiService.transactions[transactionIndex];
          }
        }
        
        // If credits and debits don't balance, open a dialog box for confirmation
        if ((creditCounter - debitCounter !== 0) && this.ccmcApiService.configurations.showGLBalancePrompt === true) {
          totalCounter = creditCounter - debitCounter;
          const message =
            'Are you sure you want to continue with the following Debit and Credit?';
          const dialogRef2 = this.dialog.open(DebitCeditCheckDialogComponent, {
            data: {
              message: message,
              total: totalCounter,
              credit: creditCounter,
              debit: debitCounter
            },
            panelClass: 'debit-credit__dialog'
          });

          // If user confirms, return true. Otherwise return false.
          dialogRef2.afterClosed().subscribe(data => {
            if (data === true) {
              return resolve(true);
            } else {
              return resolve(false)
            }
          });
        }
        // If credits and debits balance or there are no transactions to process, exit function and return true
        else {
          return resolve(true);
        }

    })
  }

  getBaseUrl() {
    let baseUrl = 'https://fiserv-premier-dev.nxtsoft.com';
    let productType = this.assetService.getSelectedProduct();
    if (environment.production) {
      if (productType.includes('prod')) {
        baseUrl = 'https://fiserv-premier-prod.nxtsoft.com';
      } else {
        baseUrl = 'https://fiserv-premier-test.nxtsoft.com';
      }
      if (environment.test) {
        baseUrl = 'https://fiserv-premier-test.nxtsoft.com';
      }
    }
    return baseUrl
  }
}
