import {
  Component,
  OnInit,
  ViewChild,
  OnDestroy,
  ElementRef
} from '@angular/core';
import { Subject, Subscription, fromEvent } from 'rxjs';
import {
  takeUntil,
  map,
  debounceTime,
  distinctUntilChanged,
  first
} from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AssetService } from 'src/@ccmc/services/asset.service';
import { ActiveCoreService } from 'src/@ccmc/services/active-core.service';
import { AdminApiService } from 'src/@ccmc/services/admin-api.service';
import { ReadLoggingService } from '../../logging/read-logging.service';
import { SpinnerService } from 'src/@ccmc/services/spinner.service';
import { CcmcApiService } from 'src/@ccmc/services/ccmc-api.service';
import { ErrorDialogComponent } from 'src/@ccmc/components/error-dialog/error-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';
@Component({
  selector: 'app-core-group',
  templateUrl: './core-group.component.html',
  styleUrls: ['./core-group.component.scss']
})
export class CoreGroupComponent implements OnInit, OnDestroy {
  unsubscribe: Subject<any> = new Subject();
  private logsSub: Subscription;
  private spinnerSub: Subscription;
  logs: any;
  currentLog: any;
  currentMessage: any;
  currentException: any;
  displayedColumns = ['timestamp', 'level', 'user', 'loanNumber', 'message'];
  dataSource: any;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('filterSearch', { static: true }) filterSearchEl: ElementRef;
  filterSearch: string;
  showSpinner: boolean;
  maxDate = new Date();
  endDate = new FormControl(new Date());
  startDate = new FormControl(
    new Date(this.endDate.value.getTime() - 24 * 60 * 60 * 1000)
  );
  loggingEnvironment: any;
  lambdaName: string;
  /**
   * Creates an instance of LoggingComponent.
   * @param {ReadLoggingService} readLoggingService
   * @param {SpinnerService} spinnerService
   * @param {CcmcApiService} ccmcAPIService
   * @param {AssetService} assetService
   * @param {ActiveCoreService} activeCoreService
   * @param {AdminApiService} adminApiService
   * @memberof LoggingComponent
   */
  constructor(
    private readLoggingService: ReadLoggingService,
    private spinnerService: SpinnerService,
    private assetService: AssetService,
    private activeCoreService: ActiveCoreService,
    private adminApiService: AdminApiService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar
  ) { }

  /**
   * On Init
   *
   * @memberof LoggingComponent
   */
  async ngOnInit() {
    const retrieveLambdaNameResponse = await this.retrieveLambdaName();
    console.log({ retrieveLambdaNameResponse });
    if (!retrieveLambdaNameResponse.statusFlag) {
      console.error(retrieveLambdaNameResponse.statusMessage);
      return;
    }
    this.lambdaName = retrieveLambdaNameResponse.content;

    if (environment.production) {
      this.loggingEnvironment = 'prod';
    } else if (environment.test) {
      this.loggingEnvironment = 'test';
    } else {
      this.loggingEnvironment = 'dev';
    }
    // Get Logs
    //   this.getLogs();
    // Subscribe to data needed
    this.getData();
    // Init filter search
    this.initFilterSearch();
    // Subscribe to the spinner
    this.spinnerSub = this.spinnerService.spinner.subscribe(spinner => {
      this.showSpinner = spinner;
    });
  }

  /**
   * Unsubscribe from observables on destroy
   *
   * @memberof LoggingComponent
   */
  ngOnDestroy() {
    this.unsubscribe.next(0);
    this.unsubscribe.complete();
  }

  // TODO: Implement clean xml that works in web
  cleanXml(xml: any) {
    return xml;
  }

  /**
   * Get Data
   * @description Gets data needed for logging component
   *
   * @memberof LoggingComponent
   */
  getData() {
    // Init data as empty array
    this.dataSource = new MatTableDataSource([]);
    // Subscribe to logs
    this.logsSub = this.readLoggingService.logs
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(logs => {
        // Turn spinner off
        // this.spinnerService.setShowSpinner(false);
        if (logs) {
          // Set logs
          this.logs = logs;
          if (logs.length > 0) {
            console.log(this.logs);
            // Set table data
            this.dataSource = new MatTableDataSource(this.logs);
            // initializes pagination
            this.dataSource.paginator = this.paginator;
            // initializes sort
            this.dataSource.sort = this.sort;
            // If filter search is
            if (this.filterSearch) {
              if (this.filterSearch.length > 0) {
                // Apply filter search on search string
                this.applyFilter(this.filterSearch);
              }
            } else {
              // Select first instance
              this.onSelect(this.dataSource.data[0]);
            }
          } else {
            // Set table to empty
            this.dataSource = new MatTableDataSource([]);
            this.currentLog = undefined;
          }
        }
      });
    //  Subscribe to the spinner
    this.spinnerSub = this.spinnerService.spinner
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(spinner => {
        this.showSpinner = spinner;
      });
  }

  /**
   * Apply filter search
   * @description Filters the datasource on the filtered value
   * @param {string} filterValue
   * @memberof LoggingComponent
   */
  applyFilter(filterValue: string) {
    // Trim the data
    this.dataSource.filter = filterValue.trim().toLowerCase();
    // If there is data
    if (this.dataSource.filteredData[0]) {
      // Select the first instance
      this.onSelect(this.dataSource.filteredData[0]);
    }
    // Set filter search
    this.filterSearch = filterValue;
  }

  /**
   * On Select
   * @description Selects current log
   * @param {*} selected
   * @memberof LoggingComponent
   */
  onSelect(selected: any) {
    // Set current log to selected
    this.currentLog = selected;
    // Clean XML
    this.currentMessage = this.cleanXml(this.xmlFormat(selected));
    //this.xmlFormat(selected);
    // Set Exception
    this.currentException = selected.exception;
  }

  /**
   * formatting of the XML
   * @description Selects current log
   * @param {*} selected
   * @memberof LoggingComponent
   */
  xmlFormat(selected: any) {
    if (selected.message.includes('<')) {
      let index = selected.message.indexOf('<');
      let subString =
        selected.message.slice(0, index) + '\n' + selected.message.slice(index);
      const xmlString = subString
        .trim()
        .replace(/>\s*</g, '>\n<')
        .replace(/(<[^\/>].*>)\n(<[\/])/g, '$1$2')
        .replace(/(<\/[^>]+>|<[^>]+\/>)(<[^>]+>)/g, '$1\n$2');
      const xmlArr = xmlString.split('\n');

      var tabs = '';
      var start = 0;
      if (/^<[?]xml/.test(xmlArr[0])) start++;
      for (var i = start; i < xmlArr.length; i++) {
        var line = xmlArr[i].trim();
        if (/^<[/]/.test(line)) {
          tabs = tabs.replace(/.$/, '');
          xmlArr[i] = tabs + line;
        } else if (/<.*>.*<\/.*>|<.*[^>]\/>/.test(line)) {
          xmlArr[i] = tabs + line;
        } else {
          xmlArr[i] = tabs + line;
          tabs += '\t';
        }
      }
      return xmlArr.join('\n');
    }
    return selected.message;
  }

  /**
   * Get Logs
   * @description Retrieves logs for current asset and environment
   * @memberof LoggingComponent
   */
  async getLogs() {
    if (this.showSpinner) {
      return;
    }
    this.spinnerService.setShowSpinner(true);
    this.readLoggingService.clearData();
    // Get core
    let core = this.activeCoreService.activeCore;
    console.log(core);
    let coreCompany;
    coreCompany = this.assetService.getSelectedCoreCompany();
    // Set lower and upper bounds for times
    const finalStartDate = this.endDate.value.setHours(0, 0, 0, 0);
    const finalEndDate = this.endDate.value.setHours(23, 59, 59, 999);
    // Check needed to have naming convention work consistently
    if (core === 'silverlake' || core === '2020' || core === 'coredirector') {
      core = 'jxchange';
      coreCompany = 'jack-henry';
    }
    if (core === 'symitar') {
      core = 'symxchange';
      coreCompany = 'jack-henry';
    }
    
    // Init logging params
    let loggingParams = {
      core: core,
      coreCompany: coreCompany,
      lambdaName: this.lambdaName,
      startDate: new Date(finalStartDate).getTime(),
      endDate: new Date(finalEndDate).getTime(),
      environment: this.loggingEnvironment
    };
    let getLogsResponse: any;
    let logs: any = [];

    // Get logs
    getLogsResponse = await this.getCloudWatchLogs(loggingParams);
    if (!getLogsResponse.statusFlag) {
      this.dialog.open(ErrorDialogComponent, {
        data: {
          title: 'Error',
          message: getLogsResponse.statusMessage
        }
      });
      return;
    }
    if (getLogsResponse.content.nextToken) {
      let currentNextToken: any = getLogsResponse.content.nextToken;
      let getMoreLogsResponse: any;
      logs = [...logs, ...getLogsResponse.content.events];
      // Loop getting more logs until we get an empty response
      do {
        if (getMoreLogsResponse && getMoreLogsResponse.content.nextToken) {
          currentNextToken = getMoreLogsResponse.content.nextToken;
        }

        let moreLoggingParams = {
          core: core,
          coreCompany: coreCompany,
          lambdaName: this.lambdaName,
          startDate: new Date(finalStartDate).getTime(),
          endDate: new Date(finalEndDate).getTime(),
          environment: this.loggingEnvironment,
          nextToken: currentNextToken
        };
        // Get More Logs
        getMoreLogsResponse = await this.getCloudWatchLogs(moreLoggingParams);
        if(getMoreLogsResponse.content.events && getMoreLogsResponse.content.events.length === 0) {
          console.log("No more logs to fetch");
          break;
        }
        if (!getMoreLogsResponse.statusFlag) {
          this.dialog.open(ErrorDialogComponent, {
            data: {
              title: 'Error',
              message: getMoreLogsResponse.statusMessage
            }
          });
          return;
        }

        logs = [...logs, ...getMoreLogsResponse.content.events];
        console.log('Current Token', currentNextToken);
        this.openSnackBar('Fetching logs, current total: ' + logs.length, 'Okay');
      } while (currentNextToken);
      this.openSnackBar('Finished fetching logs', 'Okay');
      this.readLoggingService.cleanLogs(logs);
    }
  }

  /**
   * Set Environment
   * @description Set the environment to look for logs in
   * dev, test, prod
   * @param {*} env
   * @memberof LoggingComponent
   */
  setEnvironment(env: any) {
    this.loggingEnvironment = env;
  }

  /**
   * Init Filter Search
   * @description initializes filter search and listens for keyup event
   * @memberof LoggingComponent
   */
  initFilterSearch() {
    // Auto Focus filter search item
    this.filterSearchEl.nativeElement.focus();
    fromEvent(this.filterSearchEl.nativeElement, 'keyup')
      .pipe(
        // get value
        map((event: any) => {
          return event.target.value;
        }),
        // Time in milliseconds between key events
        debounceTime(1000),
        // If previous query is diffent from current
        distinctUntilChanged()
        // subscription for response
      )
      .subscribe((text: string) => {
        this.applyFilter(text);
      });
  }

  async getCloudWatchLogs(body: any) {
    return new Promise((resolve) => {
      this.readLoggingService.newGetLogs(body).subscribe((result: any) => {
        const getLogsResponse = JSON.parse(JSON.stringify(result));
        console.log('get logs response', getLogsResponse);
        return resolve(getLogsResponse);
      });
    });
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }

  async retrieveLambdaName(): Promise<{
    statusFlag: boolean;
    statusMessage: string;
    content: string;
  }> {
    return new Promise((resolve) => {
      this.adminApiService
        .getCoreConnection(this.assetService.getSelectedCore())
        .subscribe((res) => {
          const coreConnectionInformation = JSON.parse(JSON.stringify(res));
          if (coreConnectionInformation?.coreName) {
            return resolve({
              statusFlag: true,
              statusMessage: 'Successfully retrieved core lambda name',
              content: coreConnectionInformation.lambdaName,
            });
          } else {
            return resolve({
              statusFlag: false,
              statusMessage: 'Failed to retrieve core lambda name',
              content: '',
            });
          }
        });
    });
  }
}
