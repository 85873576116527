import { Injectable } from '@angular/core';
import { navigation } from 'src/app/navigation/navigation';
import { CcmcApiService } from './ccmc-api.service';

@Injectable({
  providedIn: 'root'
})
export class ConditionsService {
  dynamicNav: any = navigation;
  constructor(private ccmcApiService: CcmcApiService) { }

  /**
   * Evaluate Conditions
   * @description Evaluates conditions as a whole
   * @memberof ConditionsService
   */
  async evaluateConditions() {
    if (this.ccmcApiService.conditions.length > 0) {
      this.ccmcApiService.transactions.forEach((transaction: any) => {
        if (transaction.alwaysDisplay === false) {
          transaction.display = false;
        this.dynamicNav[2].children[1].children.forEach((transNavItem : any) => {
          if(transaction.transactionID === transNavItem.id) {
            transNavItem.visible = false;
          }
          });
        }
      });
      for (let i = 0; i < this.ccmcApiService.conditions.length; i++) {
        const condition = this.ccmcApiService.conditions[i];
        let condition1;
        let condition2;
        // console.log(condition);

        const fieldID1Index = this.ccmcApiService.mapping.findIndex(
          (field: any) => field.fieldID === condition.fieldID1
        );
        const fieldID2Index = this.ccmcApiService.mapping.findIndex(
          (field: any) => field.fieldID === condition.fieldID2
        );

        // console.log(fieldID1Index);
        // console.log(fieldID2Index);
        // console.log(this.ccmcApiService.mapping[fieldID2Index].fieldValue);
        // console.log(condition.value2);
        // console.log(this.ccmcApiService.mapping[fieldID2Index].fieldValue !== condition.value2);

        // sets the condition 1
        if (
          this.ccmcApiService.conditions[i].cond1 === '0' ||
          this.ccmcApiService.conditions[i].cond1 === '1'
        ) {
          // tslint:disable-next-line:max-line-length
          condition1 =
            condition.cond1 === '0'
              ? this.ccmcApiService.mapping[fieldID1Index].fieldValue !==
              condition.value1
              : this.ccmcApiService.mapping[fieldID1Index].fieldValue ===
              condition.value1;
        } else if (condition.cond1 === '2' || condition.cond1 === '3') {
          // tslint:disable-next-line:max-line-length
          condition1 =
            condition.cond1 === '2'
              ? this.ccmcApiService.mapping[fieldID1Index].fieldValue <=
              condition.value1
              : this.ccmcApiService.mapping[fieldID1Index].fieldValue >=
              condition.value1;
        }

        // sets condition 2
        if (
          (condition.cond2 === '0' || condition.cond2 === '1') &&
          fieldID2Index >= 0
        ) {
          // tslint:disable-next-line:max-line-length
          condition2 =
            condition.cond2 === '0'
              ? this.ccmcApiService.mapping[fieldID2Index].fieldValue !==
              condition.value2
              : this.ccmcApiService.mapping[fieldID2Index].fieldValue ===
              condition.value2;
        } else if (
          (condition.cond2 === '2' || condition.cond2 === '3') &&
          fieldID2Index >= 0
        ) {
          // tslint:disable-next-line:max-line-length
          condition2 =
            condition.cond1 === '2'
              ? this.ccmcApiService.mapping[fieldID2Index].fieldValue <=
              condition.value2
              : this.ccmcApiService.mapping[fieldID2Index].fieldValue >=
              condition.value2;
        }

        // checks if operation between condition 1 & 2 is "or", "and" or if there is only one condition
        if (condition.operator.toLowerCase() === 'and') {
          if (condition1 && condition2) {
            condition.conditionalDefaults.forEach((defaults: any) => {
              const defaultIndex = this.ccmcApiService.mapping.findIndex(
                (map: any) => map.fieldID === defaults.fieldID
              );
              if (condition.forceData === true || defaults.forceData === true) {
                this.ccmcApiService.mapping[defaultIndex].fieldValue =
                  defaults.defaultValue;
              } else if (
                this.ccmcApiService.mapping[defaultIndex].fieldValue === ''
              ) {
                this.ccmcApiService.mapping[defaultIndex].fieldValue =
                  defaults.defaultValue;
              }
            });
            condition.conditionalTransactions.forEach((trans: any) => {
              const transactionIndex = this.ccmcApiService.transactions.findIndex(
                (t: any) => t.transactionID.toLowerCase() === trans.toLowerCase()
              );
              this.ccmcApiService.transactions[transactionIndex].display = true;
            });
            try {
              condition.conditionalRequired.forEach((field: any) => {
                const fieldIndex = this.ccmcApiService.mapping.findIndex(
                  (m: any) => m.fieldID.toLowerCase() === field.toLowerCase()
                );
                this.ccmcApiService.mapping[fieldIndex].required = true;
              });
            } catch (err) {
              console.log(err);
            }
          }
        } else if (condition.operator.toLowerCase() === 'or') {
          if (condition1 || condition2) {
            condition.conditionalDefaults.forEach((defaults: any) => {
              const defaultIndex = this.ccmcApiService.mapping.findIndex(
                (map: any) => map.fieldID === defaults.fieldID
              );
              if (condition.forceData === true || defaults.forceData === true) {
                this.ccmcApiService.mapping[defaultIndex].fieldValue =
                  defaults.defaultValue;
              } else if (
                this.ccmcApiService.mapping[defaultIndex].fieldValue === ''
              ) {
                this.ccmcApiService.mapping[defaultIndex].fieldValue =
                  defaults.defaultValue;
              }
            });
            condition.conditionalTransactions.forEach((trans: any) => {
              const transactionIndex = this.ccmcApiService.transactions.findIndex(
                (t: any) => t.transactionID.toLowerCase() === trans.toLowerCase()
              );
              this.ccmcApiService.transactions[transactionIndex].display = true;
            });
            try {
              condition.conditionalRequired.forEach((field: any) => {
                const fieldIndex = this.ccmcApiService.mapping.findIndex(
                  (m: any) => m.fieldID.toLowerCase() === field.toLowerCase()
                );
                this.ccmcApiService.mapping[fieldIndex].required = true;
              });
            } catch (err) {
              console.log(err);
            }
          }
        } else {
          if (condition1) {
            condition.conditionalDefaults.forEach((defaults: any) => {
              const defaultIndex = this.ccmcApiService.mapping.findIndex(
                (map: any) => map.fieldID === defaults.fieldID
              );
              if (condition.forceData === true || defaults.forceData === true) {
                this.ccmcApiService.mapping[defaultIndex].fieldValue =
                  defaults.defaultValue;
              } else if (
                this.ccmcApiService.mapping[defaultIndex].fieldValue === ''
              ) {
                this.ccmcApiService.mapping[defaultIndex].fieldValue =
                  defaults.defaultValue;
              }
            });
            condition.conditionalTransactions.forEach((trans: any) => {
              // console.log(trans);
              const transactionIndex = this.ccmcApiService.transactions.findIndex(
                (t: any) => t.transactionID.toLowerCase() === trans.toLowerCase()
              );

              this.ccmcApiService.transactions[transactionIndex].display = true;
            });
            try {
              condition.conditionalRequired.forEach((field: any) => {
                const fieldIndex = this.ccmcApiService.mapping.findIndex(
                  (m: any) => m.fieldID.toLowerCase() === field.toLowerCase()
                );
                if (fieldIndex > -1) {
                  this.ccmcApiService.mapping[fieldIndex].required = true;
                }
              });
            } catch (err) {
              console.log(err);
            }
          }
        }
      }
    }
  }

  /**
   * Evaluate Conditions By Field
   * @description Evaluates condition by field
   * @param {*} updatedField
   * @memberof ConditionsService
   */
  async evaluateConditionByField(updatedField: any) {
    // pushed transactions that have been turned off to an array, so transactions that have already been evaluated do not get overridden
    const evaluatedtransactions: any[] = [];
    if (this.ccmcApiService.conditions.length > 0) {
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < this.ccmcApiService.conditions.length; i++) {
        const condition = this.ccmcApiService.conditions[i];
        let condition1;
        let condition2;
        if (
          condition.fieldID1 === updatedField.fieldID ||
          condition.fieldID2 === updatedField.fieldID
        ) {
          condition.conditionalTransactions.forEach((conditionalTransaction: any) => {
            // tslint:disable-next-line: max-line-length
            // console.log(evaluatedtransactions);
            // tslint:disable-next-line: max-line-length
            const evaluatedtransactionIndex = evaluatedtransactions.findIndex(
              e =>
                e.transactionID !== undefined &&
                e.transactionID.toLowerCase() ===
                conditionalTransaction.toLowerCase()
            );
            if (evaluatedtransactionIndex === -1) {
              // tslint:disable-next-line:max-line-length
              const transactionIndex = this.ccmcApiService.transactions.findIndex(
                (trans: any) =>
                  trans.transactionID.toLowerCase() ===
                  conditionalTransaction.toLowerCase()
              );
              this.ccmcApiService.transactions[
                transactionIndex
              ].display = false;
              evaluatedtransactions.push(conditionalTransaction);
              let transactionIndexNav = this.dynamicNav[2].children[1].children
              .findIndex((trans: any) => trans.id === this.ccmcApiService.transactions[transactionIndex].transactionID);
              if(transactionIndexNav > -1) {
                this.dynamicNav[2].children[1].children[transactionIndexNav].visible = false;
              }
            }
          });
          const fieldID1Index = this.ccmcApiService.mapping.findIndex(
            (field: any) => field.fieldID === condition.fieldID1
          );
          const fieldID2Index = this.ccmcApiService.mapping.findIndex(
            (field: any) => field.fieldID === condition.fieldID2
          );


          // sets the condition 1
          if (condition.cond1 === '0' || condition.cond1 === '1') {
            // tslint:disable-next-line:max-line-length
            condition1 =
              condition.cond1 === '0'
                ? this.ccmcApiService.mapping[fieldID1Index].fieldValue !==
                condition.value1
                : this.ccmcApiService.mapping[fieldID1Index].fieldValue ===
                condition.value1;
          } else if (condition.cond1 === '2' || condition.cond1 === '3') {
            // tslint:disable-next-line:max-line-length
            condition1 =
              condition.cond1 === '2'
                ? this.ccmcApiService.mapping[fieldID1Index].fieldValue <=
                condition.value1
                : this.ccmcApiService.mapping[fieldID1Index].fieldValue >=
                condition.value1;
          }

          // sets condition 2
          if (condition.cond2 === '0' || condition.cond2 === '1') {
            // tslint:disable-next-line:max-line-length
            condition2 =
              condition.cond2 === '0'
                ? this.ccmcApiService.mapping[fieldID2Index].fieldValue !==
                condition.value2
                : this.ccmcApiService.mapping[fieldID2Index].fieldValue ===
                condition.value2;
          } else if (condition.cond2 === '2' || condition.cond2 === '3') {
            // tslint:disable-next-line:max-line-length
            condition2 =
              condition.cond1 === '2'
                ? this.ccmcApiService.mapping[fieldID2Index].fieldValue <=
                condition.value2
                : this.ccmcApiService.mapping[fieldID2Index].fieldValue >=
                condition.value2;
          }

          // checks if operation between condition 1 & 2 is "or", "and" or if there is only one condition
          if (condition.operator.toLowerCase() === 'and') {
              if(condition.value1 === updatedField.fieldValue) {
              const defaultIndex = this.ccmcApiService.mapping.findIndex(
                (map: any) => map.fieldID === condition.conditionalDefaults[0].fieldID
              );
              let locDefaultValue = this.readDefaultValue(condition.conditionalDefaults[0]);
              if (condition.forceData === true || 
                condition.conditionalDefaults[0].forceData === true ||
                this.ccmcApiService.mapping[defaultIndex].fieldValue !== null && 
                  updatedField.fieldValue !== '') {
                this.ccmcApiService.mapping[defaultIndex].fieldValue = locDefaultValue;
              } 
            } else { 
              const defaultIndex = this.ccmcApiService.mapping.findIndex(
                (map: any) => map.fieldID === condition.conditionalDefaults[0].fieldID
              );
              if(updatedField.fieldValue === '') {
                this.ccmcApiService.mapping[defaultIndex].fieldValue = '';
              }
            }
              condition.conditionalTransactions.forEach((trans: any) => {
                // console.log(trans);
                const transactionIndex = this.ccmcApiService.transactions.findIndex(
                  (t: any) => t.transactionID.toLowerCase() === trans.toLowerCase()
                );
                this.ccmcApiService.transactions[
                  transactionIndex
                ].display = true;
              });
              try {
                condition.conditionalRequired.forEach((field: any) => {
                  const fieldIndex = this.ccmcApiService.mapping.findIndex(
                    (m: any) => m.fieldID.toLowerCase() === field.toLowerCase()
                  );
                  if(updatedField.fieldValue === '') {
                    this.ccmcApiService.mapping[fieldIndex].required = false;
                  } else {
                    this.ccmcApiService.mapping[fieldIndex].required = true;
                  }
                });
              } catch (err) {
                console.log(err);
              }
          } else if (condition.operator.toLowerCase() === 'or') {
            if(condition.value1 === updatedField.fieldValue) {
              const defaultIndex = this.ccmcApiService.mapping.findIndex(
                (map: any) => map.fieldID === condition.conditionalDefaults[0].fieldID
              );
              let locDefaultValue = this.readDefaultValue(condition.conditionalDefaults[0]);
              if (condition.forceData === true || 
                condition.conditionalDefaults[0].forceData === true ||
                this.ccmcApiService.mapping[defaultIndex].fieldValue !== null && 
                  updatedField.fieldValue !== '') {
                this.ccmcApiService.mapping[defaultIndex].fieldValue = locDefaultValue;
              } 
            } else { 
              const defaultIndex = this.ccmcApiService.mapping.findIndex(
                (map: any) => map.fieldID === condition.conditionalDefaults[0].fieldID
              );
              if(updatedField.fieldValue === '') {
                this.ccmcApiService.mapping[defaultIndex].fieldValue = '';
              }
            }
              condition.conditionalTransactions.forEach((trans: any) => {
                // console.log(trans);
                const transactionIndex = this.ccmcApiService.transactions.findIndex(
                  (t: any) => t.transactionID.toLowerCase() === trans.toLowerCase()
                );
                this.ccmcApiService.transactions[
                  transactionIndex
                ].display = true;
              });
              try {
                condition.conditionalRequired.forEach((field:any) => {
                  const fieldIndex = this.ccmcApiService.mapping.findIndex(
                    (m: any) => m.fieldID.toLowerCase() === field.toLowerCase()
                  );
                  if(updatedField.fieldValue === '') {
                    this.ccmcApiService.mapping[fieldIndex].required = false;
                  } else {
                    this.ccmcApiService.mapping[fieldIndex].required = true;
                  }
                });
              } catch (err) {
                console.log(err);
              }
          } else {
            if(condition.conditionalDefaults.length > 0) {
              if(condition.value1 === updatedField.fieldValue) {
                const defaultIndex = this.ccmcApiService.mapping.findIndex(
                  (map: any) => map.fieldID === condition.conditionalDefaults[0].fieldID
                );
                let locDefaultValue = this.readDefaultValue(condition.conditionalDefaults[0]);
                if (condition.forceData === true || 
                  condition.conditionalDefaults[0].forceData === true ||
                  this.ccmcApiService.mapping[defaultIndex].fieldValue !== null && 
                    updatedField.fieldValue !== '') {
                  this.ccmcApiService.mapping[defaultIndex].fieldValue = locDefaultValue;
                } 
              } else { 
                const defaultIndex = this.ccmcApiService.mapping.findIndex(
                  (map: any) => map.fieldID === condition.conditionalDefaults[0].fieldID
                );
                if(updatedField.fieldValue === '') {
                  this.ccmcApiService.mapping[defaultIndex].fieldValue = '';
                }
              }
            }
            
              condition.conditionalTransactions.forEach((trans: any) => {
                // console.log(trans);
                const transactionIndex = this.ccmcApiService.transactions.findIndex(
                  (t: any) => t.transactionID.toLowerCase() === trans.toLowerCase()
                );
                // console.log(this.ccmcApiService.transactions[transactionIndex]);
                this.ccmcApiService.transactions[
                  transactionIndex
                ].display = true;
              });
              try {
                condition.conditionalRequired.forEach((field: any) => {
                  const fieldIndex = this.ccmcApiService.mapping.findIndex(
                    (m: any) => m.fieldID.toLowerCase() === field.toLowerCase()
                  );
                  if(updatedField.fieldValue === '') {
                    this.ccmcApiService.mapping[fieldIndex].required = false;
                  } else {
                    this.ccmcApiService.mapping[fieldIndex].required = true;
                  }
                });
              } catch (err) {
                console.log(err);
              }
          }
        }
      }
    }
  }

  readDefaultValue (defaults: any) {
    let locDefaultValue = defaults.defaultValue
    if (locDefaultValue[0] == '"' && locDefaultValue[locDefaultValue.length-1] == '"') {
      let sourceField = locDefaultValue.substring(1, locDefaultValue.length-1)
      const sourceIndex = this.ccmcApiService.mapping.findIndex(
        (map: any) => map.fieldID === sourceField
      )
      if (sourceIndex >= 0) {
        locDefaultValue = this.ccmcApiService.mapping[sourceIndex].fieldValue
      } else {
        console.log("Field " + sourceField + " does not exist")
      }
    }
    return locDefaultValue
  }
}
